<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card
      class="mx-auto v-card--wizard"
      id="conciliacao-tipos-concilicao"
      elevation="12"
      max-width="700"
    >
      <v-card-title
        class="justify-center display-2 font-weight-light pt-5"
        style="word-break: break-word"
      >
        Selecione o tipo da sua conciliação
      </v-card-title>
      <v-card-text class="my-6">
        <general-card-item-group
          v-model="tipoConciliacao"
          :optionalId="true"
          :options="cardItemOptions"
          justify="center"
          @input="redirect($event)"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [tourMixins],

  components: {
    GeneralCardItemGroup: () =>
      import('@/components/general/GeneralCardItemGroup')
  },

  created() {
    this.iniciarTourPagina();
  },

  data: () => ({
    tipoConciliacao: '',
    cardItemOptions: [
      {
        value: 'EXPRESS',
        text: 'Express',
        description: 'Conciliação simplificada e de rápida configuração.',
        icon: 'mdi-play-circle-outline'
      },
      {
        value: 'CUSTOM',
        text: 'Custom',
        description: 'Conciliação avançada e com mais configurações.',
        icon: 'mdi-target'
      }
    ],
    arrTour: [
      {
        element: '#conciliacao-tipos-concilicao',
        intro:
          'Inicialmente você deve escolher qual o tipo da sua conciliação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#general-card-item-EXPRESS',
        intro:
          'O primeiro tipo é a <i>Conciliação Express</i>, que é simplificada e de rápida configuração',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#general-card-item-CUSTOM',
        intro:
          'Já o segundo tipo é a <i>Conciliação Custom</i>, que permite mais customizações e possui configurações de nível avançado.',
        scrollTo: 'tooltip',
        position: 'right'
      }
    ]
  }),

  methods: {
    redirect(param) {
      if (param) {
        const rota = 'edit/' + param.toLowerCase();
        this.$router.push({ path: rota, params: { teste: 'asd' } });
      }
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
